// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-guitar-chord-finder-js": () => import("./../../../src/pages/guitar-chord-finder.js" /* webpackChunkName: "component---src-pages-guitar-chord-finder-js" */),
  "component---src-pages-guitar-harmonizer-js": () => import("./../../../src/pages/guitar-harmonizer.js" /* webpackChunkName: "component---src-pages-guitar-harmonizer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-triads-why-are-important-js": () => import("./../../../src/pages/triads-why-are-important.js" /* webpackChunkName: "component---src-pages-triads-why-are-important-js" */),
  "component---src-pages-ukulele-chord-finder-js": () => import("./../../../src/pages/ukulele-chord-finder.js" /* webpackChunkName: "component---src-pages-ukulele-chord-finder-js" */),
  "component---src-pages-what-is-this-about-js": () => import("./../../../src/pages/what-is-this-about.js" /* webpackChunkName: "component---src-pages-what-is-this-about-js" */),
  "component---src-templates-guitar-harmonizer-template-js": () => import("./../../../src/templates/guitar_harmonizer_template.js" /* webpackChunkName: "component---src-templates-guitar-harmonizer-template-js" */)
}

